// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$navbar-height: 4rem;

// Colors
$primary: #006666;
$primary-local: #FF8749;
$primary-staging: #91E1E0;
$primary-dark: #004444;
$primary-darker: #003C3C;
$secondary: #F76E6E;
$secondary-light: #FFC1B7;
$secondary-lighter: #F6E7E4;
$secondary-accent: #F24F43;
$success: #11c15e;
$theme-dark: #002222;
$theme-light: #eeeeee;
$warning: #F6C155;
$background: #F0EEEC;
$white: #ffffff;
$black: #000000;
$grey: #6D7878;
$grey-light: #949E9E;
$grey-lighter: #BCC2C2;
$grey-pale: #CFD1CF;
$grey-dark: #67706F;
$dodow-green: #00AAAA;
$hoom-blue: #1f3466;

$border-radius : .5rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1500px
);
