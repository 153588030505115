@use '../abstracts' as *;

.Container{
    &--main{
        padding-top: $navbar-height;
    }
    &--page{
        min-height: calc(100vh - #{$navbar-height});
        position: relative;
        display: flex;
        flex-direction: column;
        right: 0;
    }
    &--json{
        overflow-x: scroll;
        background-color: rgba($primary, .05);
    }
}

.Flex{
    display: flex;
}

.Block{
    background-color: white;
    @include slight-shadow();
}

.Sidebar{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: $navbar-height 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.Breadcrumbs{
    font-size: .8rem;
}
