@use '../abstracts' as *;

.Badge{

    // Brands
    &--dodow{
        background-color: $dodow-green;
        color: $white;
    }
    &--hoom{
        background-color: $hoom-blue;
        color: $white;
    }
    &--botanikal{
        background-color: $secondary;
        color: $white;
    }

    // Feedback bundles
    &--dodow_bundle{
        background-color: $dodow-green;
        color: $white;
    }
    &--hoomband_bundle{
        background-color: $hoom-blue;
        color: $white;
    }
    &--hoombook_bundle{
        background-color: $primary-darker;
        color: $white;
    }
    &--hoomkid_bundle{
        background-color: $primary;
        color: $white;
    }
    &--dodow_xmas_bundle{
        background-color: #d7143b;
        color: $white;
    }
    &--hoomband_xmas_bundle{
        background-color: #870B24;
        color: $white;
    }
}
