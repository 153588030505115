@use '../abstracts' as *;

.Timeline{
    &--date, &--content{
        position: relative;

        // Triangles pointing to timeline
        &::after{
            content: '';
            width: 0;
            height: 0;
            border-top: .5rem solid transparent;
            border-bottom: .5rem solid transparent;
            position: absolute;
            top: .75rem;
        }
        
    }
    &--date{
        display: inline-block;

        &-ctn{
            border-right: 2px solid $grey-pale;
        }

        // Triangle pointing to the right
        &::after{
            border-left: .75rem solid $primary;
            right: 0;
            transform: translateX(100%);
        }
    }

    &--content{
        position: relative;

        // Triangle pointing to the left
        &::after{
            border-right: .75rem solid $white;
            left: 0;
            transform: translateX(-100%);
        }

        // Pink circle on timeline
        &::before{
            content: "";
            width: 0.5rem;
            height: 0.5rem;
            position: absolute;
            left: -1.8rem;
            top: 1rem;
            background-color: $white;
            border: 2px solid $secondary;
            border-radius: 50%;
        }
    }
}
