@use '../abstracts' as *;

.Button{
    &--white{
        background-color: $primary-darker;
        color: $white;
        border: 2px solid $white;
        font-weight: bold;
        
        &:hover, &:focus{
            background-color: $primary-dark;
            color: $white;
        }
    }

    &--action{
        padding: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        
        &-regular{
            height: 2rem;
            width: 2rem;
        }
        &-small{
            font-size: .8rem;
            height: 1.75rem;
            width: 1.75rem;
            font-size: .8rem;
        }
    }
    &--flip{
        transition: transform 600ms ease;
        &-active{
            transform: rotate(180deg);
        }
    }
}

.modal-header .Button--action{
    margin-left: .5rem;
    margin-right: .5rem;
}

.list-group-item-action.active .text-muted{
    color: rgba($white, .8)!important;
}
