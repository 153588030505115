@use '../abstracts' as *;

.Table{
    & thead{
        color: $primary;
    }
    &--rounded-wrap{
        &, & table, & table thead, & table thead tr:first-child, & table thead td:first-child{
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }
        & table thead tr:first-child th:first-child,
        & table thead tr:first-child td:first-child{
            border-top-left-radius: $border-radius;
        }
        & table thead tr:first-child th:last-child{
            border-top-right-radius: $border-radius;
        }
        &, & table, & table tbody, & table thead tr:last-child{
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
        & table tbody tr:last-child td:first-child{
            border-bottom-left-radius: $border-radius;
        }
        & table tbody tr:last-child td:last-child{
            border-bottom-right-radius: $border-radius;
        }
    }
    &--cell{
        &-nowrap{
            white-space: nowrap;
        }
    }
    &--fixed-header{
        overflow: scroll;
        max-height: calc(100vh - #{$navbar-height} - 9.5rem);
        thead tr:first-child th{
            background: $white;
            position: sticky;
            top: 0;
            z-index: 10;

            &:first-child{
                left: 0;
                z-index: 15;
            }
        }
        table thead tr:first-child th::after{
            content: "";
            width: 100%;
            height: .5px;
            background-color: $primary;
            display: block;
            position: absolute;
            bottom: -1px;
            left: 0;
        }
    }
    &--row{
        &-muted{
            td, th{
                box-shadow: unset;
                background-color: rgba($grey-pale, .15);
            }
        }
        &-hidden{
            display: none;
        }
        &-header-sticky{
            position: sticky;
            left:0;

            // Fix to imitate bootstrap borders
            &::before, &::after{
                content: "";
                width: 100%;
                height: 1px;
                background-color: #dee2e6;
                display: block;
                position: absolute;
                left: 0;
                z-index: 10;
            }
            &:before{
                top: -1px;
            }
            &:after{
                bottom: -1px;
            }

            
        }
    }

}

table tbody tr:first-child .Table--row-header-sticky::before{
    background-color: $primary;
}
