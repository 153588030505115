@use 'variables';
@use 'sass:map';

// Theme colors
$primary: variables.$primary;
$secondary: variables.$secondary;
$warning: variables.$warning;
$success: variables.$success;
$danger: variables.$secondary-accent;
$info: variables.$dodow-green;
$dark: variables.$theme-dark;
$light: variables.$theme-light;
$body-bg: variables.$background;

// Fonts
$font-size-base: .9rem;

// Forms
$input-bg: variables.$white;

// Tables
$table-accent-bg: variables.$white;
$table-active-bg: rgba(variables.$primary, .05);
$table-striped-bg: rgba(variables.$primary, .05);
$table-hover-bg: rgba(variables.$warning, .15);

// List group
$list-group-hover-bg: rgba(variables.$warning, .15);

// Accordion
$accordion-button-bg: rgba(variables.$primary, .1);

// Breadcrumbs
$breadcrumb-divider: quote(">");

// Breakpoints
$grid-breakpoints: variables.$grid-breakpoints;

@import '~bootstrap/scss/bootstrap';

.bg-primary-light{
    background-color: rgba($primary, .05);
}

.form-label{
    font-weight: bold;
}
