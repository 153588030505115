@use '../abstracts' as *;

body.Auth, html.Auth{
    height: 100%;
}

body.Auth{
    display: flex;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: $primary;
    color: $white;
    text-align: center;
}

.Auth{
    &--alert{
        color: $secondary-light;
    }
}
