@use '../abstracts' as *;

.Header{
    height: $navbar-height;
    &--toggle.navbar-toggler, &--logout{
        height: 2.5rem;
        min-width: 2.5rem;
        align-self: center;
        border-radius: 50%;
        padding: 0;
        &:focus{
            box-shadow: none;
            background-color: $primary-darker;
            border: 2px solid $white;
        }
    }
    @media screen and (min-width: map-get($grid-breakpoints, 'xxxl')){
        &--toggle{
            display: none;
        }
    }
    &--logo{
        height: 1.75rem;
    }

    &--search{
        display: none;
    }

    &--local {
        background-color: $primary-local !important;
    }

    &--staging {
        background-color: $primary-staging !important;
    }

    @media screen and (min-width: map-get($grid-breakpoints, 'md')){
        &--search{
            display: block;
        }
    }
}

.Nav{
    @media screen and (min-width: map-get($grid-breakpoints, 'xxxl')){
        &--header{
            display: none;
        }
    }
    &--subtitle{
        font-variant: small-caps;
        font-size: .8rem;
        padding-left: .75rem;
    }
    &--icon{
        text-align: center;
        display: inline-block;
        width: 2rem;
    }
    &--list{
        > li > button.btn{
            position: relative;
            width: 100%;
            text-align: left;
            padding-top: 0;
            padding-bottom: 0;
        }
        &-title{
            &::after{
                width: 1.25em;
                line-height: 0;
                content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,102,102,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
                transition: transform .35s ease;
                transform-origin: .5em 50%;
                position: absolute;
                right: 0;
                top: .75em;
            }
            &[aria-expanded="true"]{
                &::after{
                    transform: rotate(90deg);
                }
            }
        }
    }
}

nav ul.pagination{
    justify-content: center;
    margin-top: 3rem;
}

.Filter{
    &--dropdown{
        max-height: 25rem;
        overflow: auto;
    }

    &--date{
        &-dropdown{
            min-width: 24rem;
        }
    }
}
