@use '~@fortawesome/fontawesome-free/css/all';
@use '~flag-icons/sass/flag-icons';
@use 'abstracts/bootstrap';

// Abstracts
@use 'abstracts' as *;

// Variables
@use 'components/containers';
@use 'components/cards';
@use 'components/forms';
@use 'components/buttons';
@use 'components/badges';
@use 'components/tables';
@use 'components/nav';
@use 'components/page_header';
@use 'components/timelines';
@use 'pages/auth';

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Hide TinyMCE "Upgrade" button
.tox .tox-promotion .tox-promotion-link{
    visibility: hidden !important;
}
