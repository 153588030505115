@use '../abstracts' as *;

.form-floating{

    &, .form-control{
        color: $primary-darker;
    }
    
    input[type="email"]{
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    input[type="password"]{
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}


.Form{
    &--auth{
        margin: auto;
        min-width: 20rem;
        max-width: min(20rem, 100vw);

        input:checked {
            background-color: $secondary;
            border-color: $secondary;
        }

        a{
            color: $secondary-light;
        }
    }

    .input-group{
        .input-group-text:first-child, input[type="number"].form-control:first-child{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        &.has-validation{
            .btn:first-child{
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            .form-control.is-valid:not(:first-child), .form-control.is-invalid:not(:first-child){
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }
            .form-control:first-child, .form-control.is-valid:first-child, .form-control.is-invalid:first-child{
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    &--label-required::after{
        content: '\00A0*';
        color: $primary;
    }
}
