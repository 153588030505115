@use '../abstracts' as *;

.Page-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.Filter{
    &--dropdown{
        max-height: 25rem;
        overflow: auto;
        &-flex.show{
            display: flex;;
        }
    }

    &--date{
        &-dropdown{
            min-width: 24rem;
        }
    }

    &--sidebar{
        .dropdown-toggle{
            width: 100%;
            position: relative;
            &::after{
                position: absolute;
                right: .75rem;
                top: 40%;
            }
        }
    }
}
